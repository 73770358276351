import $ from "jquery";
import "vegas/dist/vegas.min.js";
import "slick-carousel";

// メインビジュアル vegas.js
  $(function() {
    jQuery(".js-vegas").vegas({
      slides: [{
          src: "wp-content/themes/maishu/dist/img/slide1.jpg"
        },
        {
          src: "wp-content/themes/maishu/dist/img/slide2.jpg"
        },
        {
          src: "wp-content/themes/maishu/dist/img/slide3.jpg"
        },
        {
          src: "wp-content/themes/maishu/dist/img/slide4.jpg"
        },
        {
          src: "wp-content/themes/maishu/dist/img/slide5.jpg"
        },
      ],
      transition: "fade",
      transitionDuration: 3000,
      delay: 6000,
      cover: true,
      animation: "random",
      animationDuration: 50000,
      timer: false,
    });
  });

  function activateIntroAnimation() {
    const intro = document.querySelector('.p-top-intro');
    const introBottomPosition = intro.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;

    if (introBottomPosition < windowHeight) {
      const rightItems = document.querySelectorAll('.p-top-intro__rightItem');
      rightItems.forEach((rightItem, index) => {
        setTimeout(() => {
          rightItem.classList.add('is-active');
        }, index * 1200); // 1.2秒間隔
      });

      const leftItem = document.querySelector('.p-top-intro__left');
      setTimeout(() => {
        leftItem.classList.add('is-active');
      }, 6000);
    }
  }

  document.addEventListener('scroll', () => {
    setTimeout(() => {
      activateIntroAnimation();
    }, 1000); // 1秒後に実行
  });
  
  window.onload = function() {
    setTimeout(() => {
      activateIntroAnimation();
    }, 1000); // 1秒後に実行
  };

// 京セクション スリックスライダー
$(function () {
  $(".p-top-kyotoSlider").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    autoplay: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          variableWidth: true, //左右のスライドを見せるために必要
          centerMode: true, //スライドを中心に寄せる
        },
      },
    ],
  });
});

// 文セクション スリックスライダー
$(function () {
  $(".p-top-letterSlider").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    autoplay: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
});